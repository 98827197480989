/** Add the ability to access the current user from anywhere in the App.
 * 
 * This is referenced: 
 * -
 * -
 * 
 * 
 */

import React from "react";

const UserContext = React.createContext();

export default UserContext;
